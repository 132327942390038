import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC8mdruvfZVBd8ynEisgS4JG_P63_8RbHw",
  authDomain: "issani-comments.firebaseapp.com",
  projectId: "issani-comments",
  storageBucket: "issani-comments.firebasestorage.app",
  messagingSenderId: "375433449913",
  appId: "1:375433449913:web:50deaa1008a92d40062668",
  measurementId: "G-65EVFQZ1C5"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// Initialize Services
export const db = getFirestore(app); // Initialize Firestore
export const auth = getAuth(app); // Initialize Auth
export const googleProvider = new GoogleAuthProvider();