import React from "react";
import "./ShareButtons.css";

const ShareButtons = ({ url, title, authorName, imgUrl, contentSnippet }) => {
  const encodedTitle = encodeURIComponent(title);
  const encodedSnippet = encodeURIComponent(contentSnippet);
  const encodedAuthor = encodeURIComponent(authorName);
  // const encodedImage = encodeURIComponent(imgUrl);

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}&quote=${encodedTitle}%20by%20${encodedAuthor}%0A${encodedSnippet}`;
  const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodedTitle}%20by%20${encodedAuthor}%0A${encodedSnippet}%0A${url}`;


  return (
    <div className="share-buttons">
      <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer">
        <img
          src="/assets/facebook-logo.png"
          alt="Share on Facebook"
          className="share-button-logo"
        />
      </a>
      <a href={whatsappShareUrl} target="_blank" rel="noopener noreferrer">
        <img
          src="/assets/whatsapp-logo.png"
          alt="Share on WhatsApp"
          className="share-button-logo"
        />
      </a>
    </div>
  );
};

export default ShareButtons;
