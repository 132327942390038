import React from "react";
import { Helmet } from "react-helmet";
import "./AboutUs.css";

const AboutUs = () => {
    return (
        <div className="about-us-background">
           <Helmet>
        <title>About Us - Issani</title>
        <meta name="description" content=" ಇಸ್ಸನಿ ಎಂದರೆ ಜೋರು ಮಳೆಗಾಲದಲ್ಲಿ ಬೆಚ್ಚಗಿನ ಬಟ್ಟೆ ತೊಟ್ಟು, ಕಿಟಕಿಯಿಂದ ಇಣುಕಿ ನೋಡುವಾಗ,
                        ಪುಳಕ್ಕನೆ ಮುಖಕ್ಕೆ ಬಂದು ಎರಚುವ ಸಣ್ಣ ಹನಿಗಳ ಪುಂಜ....ಆಹಾ ಇದೆಂತ ಮಧುರ ಅನುಭವ ಕೊಡುತ್ತದೆ ಅಲ್ವಾ ..
                        ಅಂತಹದೇ ಅನುಭವ ಕೊಡುವ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬರೆದು ಪ್ರಕಟಿಸುವ ಉದ್ದೇಶ ನಮ್ಮದು.." />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": "About Us",
            "url": "https://issani.co.in/about",
            "description": "Learn more about Issani and its mission to share quality Kannada content."
          })}
        </script>
      </Helmet>
            <div className="about-us-container">
                <h1 className="fade-in">ನಮ್ಮ ಬಗ್ಗೆ</h1>
                <div className="content-container fade-in">
                    <p>
                        ಕೆಲವು ವರ್ಷಗಳ ಹಿಂದೆ, ಕನ್ನಡದಲ್ಲಿ ಕಥೆ - ಕವನಗಳನ್ನು ಬರೆಯುವಂತಹ ಹುಮ್ಮಸ್ಸು ಹೊಂದಿದ್ದ
                        ಸಮಾನ ಮನಸ್ಕರ ತಂಡವೊಂದು ಕನ್ನಡ ಬರಹಗಳನ್ನು ಪ್ರಕಟಿಸಲು ಸೃಷ್ಟಿ ಮಾಡಿದ ಜಾಲ ತಾಣವೇ ಈ ಇಸ್ಸನಿ..
                    </p>
                    <p>
                        ಇಸ್ಸನಿ ಎಂದರೆ ಜೋರು ಮಳೆಗಾಲದಲ್ಲಿ ಬೆಚ್ಚಗಿನ ಬಟ್ಟೆ ತೊಟ್ಟು, ಕಿಟಕಿಯಿಂದ ಇಣುಕಿ ನೋಡುವಾಗ,
                        ಪುಳಕ್ಕನೆ ಮುಖಕ್ಕೆ ಬಂದು ಎರಚುವ ಸಣ್ಣ ಹನಿಗಳ ಪುಂಜ....ಆಹಾ ಇದೆಂತ ಮಧುರ ಅನುಭವ ಕೊಡುತ್ತದೆ ಅಲ್ವಾ ..
                        ಅಂತಹದೇ ಅನುಭವ ಕೊಡುವ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬರೆದು ಪ್ರಕಟಿಸುವ ಉದ್ದೇಶ ನಮ್ಮದು..
                    </p>
                    <p>
                        ಹೊಸ ಕನ್ನಡ ಬರಹಗಾರರಿಗೆ ವೇದಿಕೆಯಾಗಿ, ಕನ್ನಡದ ಹೊಸ ತಲೆಮಾರಿಗೆ ಕನ್ನಡದಲ್ಲಿ ಭಾವನೆಗಳನ್ನು
                        ಹಂಚಿಕೊಳ್ಳುವ ಹುಚ್ಚು ಹಿಡಿಸುವ ಸಲುವಾಗಿ, ಓದುಗರಿಗೆ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬುತ್ತಿಯಾಗಿ ಕಟ್ಟಿ ಕೊಡುವ
                        ಸಲುವಾಗಿ ಈ ಜಾಲತಾಣ ಸೃಷ್ಟಿಸಿದ್ದೇವೆ ...ನಿಮ್ಮ ಪ್ರೀತಿ, ಓದು, ಸಲಹೆ ಎಲ್ಲವೂ ಜೊತೆಗೆ ಇರಲಿ...
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
