import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Layout.css";

const Layout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {/* Header with Logo and Navigation */}
      <header className="header">
        <div className="logo">
          <Link to="/">
            <img src="/assets/logo.png" alt="Website Logo" />
          </Link>
        </div>
        {/* Hamburger Menu */}
        <button
          className="hamburger"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          ☰
        </button>
        <nav className={`navbar ${menuOpen ? "open" : ""}`}>
          <Link to="/" onClick={() => setMenuOpen(false)}>
            ಮನೆ
          </Link>
          <Link to="/list-authors" onClick={() => setMenuOpen(false)}>
            ಲೇಖಕರು
          </Link>
          <Link to="/all-ankanas" onClick={() => setMenuOpen(false)}>
            ಅಂಕಣ ಬರಹಗಳು
          </Link>
          <Link to="/about" onClick={() => setMenuOpen(false)}>
            ನಮ್ಮ ಬಗ್ಗೆ
          </Link>
          <Link to="/contact" onClick={() => setMenuOpen(false)}>
            ಸಂಪರ್ಕಿಸಿ
          </Link>
        </nav>
      </header>

      {/* Main Content */}
      <main>{children}</main>
    </>
  );
};

export default Layout;
