import React, { useState } from "react";
import { db, auth } from "./../firebase/firebase.js";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Login from "./Login";
import "./CommentForm.css";

const CommentForm = ({ postId }) => {
  const [user, setUser] = useState(null);
  const [commentText, setCommentText] = useState("");
  const [error, setError] = useState("");

  const handleLoginSuccess = (loggedInUser) => {
    setUser(loggedInUser);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user) {
      setError("You must be logged in to comment.");
      return;
    }

    try {
      await addDoc(collection(db, "comments"), {
        postId,
        commentText,
        authorName: user.displayName,
        authorEmail: user.email,
        authorPhoto: user.photoURL, // Save the photo URL
        timestamp: serverTimestamp(),
      });
      
      setCommentText("");
      setError("");
    } catch (error) {
      console.error("Error adding comment:", error);
      setError("Failed to add the comment.");
    }
  };

  return (
    <div className="comment-form-container">
      {!user ? (
        <Login onLoginSuccess={handleLoginSuccess} />
      ) : (
        <>
          <div className="user-info">
            <p>
              Posting as <strong>{user.displayName}</strong> (<em>{user.email}</em>)
            </p>
            <button className="logout-button" onClick={handleLogout}>
              Sign Out
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <textarea
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
              placeholder="Add a comment..."
              required
            />
            {error && <p className="error-message">{error}</p>}
            <button type="submit">Submit</button>
          </form>
        </>
      )}
    </div>
  );
};

export default CommentForm;
