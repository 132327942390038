import React, { useEffect, useState } from "react";
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../firebase/firebase";
import "./CommentList.css";

const CommentList = ({ postId }) => {
  const [comments, setComments] = useState([]);
  const [error, setError] = useState(null);
  const [visibleComments, setVisibleComments] = useState(2);

  useEffect(() => {
    if (!postId) {
      console.error("Invalid post ID");
      setError("Invalid post ID");
      return;
    }

    const commentsQuery = query(
      collection(db, "comments"),
      where("postId", "==", postId),
      orderBy("timestamp", "desc") // Latest comments first
    );

    const unsubscribe = onSnapshot(
      commentsQuery,
      (snapshot) => {
        const fetchedComments = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setComments(fetchedComments);
        setError(null); // Reset error state if query succeeds
      },
      (error) => {
        console.error("Error fetching comments:", error);
        setError("Failed to load comments.");
      }
    );

    return () => unsubscribe();
  }, [postId]);

  const handleLoadMore = () => {
    setVisibleComments((prev) => prev + 2);
  };

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (comments.length === 0) {
    return <div className="no-comments">No comments yet. Be the first to comment!</div>;
  }

  return (
    <div className="comment-section-container">
      <h3>Comments</h3>
      <div className="comment-list">
        {comments.slice(0, visibleComments).map((comment) => (
          <div key={comment.id} className="comment-item">
            <div className="comment-avatar">
              <img
                src={comment.authorPhoto || "/assets/default-avatar.png"} // Default avatar if none
                alt="Author DP"
                className="avatar-image"
              />
            </div>
            <div className="comment-details">
              <p className="comment-author">{comment.authorName}</p>
              <p className="comment-text">{comment.commentText}</p>
              <span className="comment-timestamp">
                {comment.timestamp?.toDate().toLocaleString() || "Just now"}
              </span>
            </div>
          </div>
        ))}
      </div>
      {visibleComments < comments.length && (
        <button className="load-more-button" onClick={handleLoadMore}>
          Load More
        </button>
      )}
    </div>
  );
};

export default CommentList;
