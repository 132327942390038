import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import "./Home.css";
import AnkanaHorizontalScroll from "./AnkanaHorizontalScroll";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 12;

  const postsSectionRef = useRef(null); // Ref for the posts section

  const fetchPosts = (page) => {
    fetch(
      `https://issani.co.in/api/api.php?api=getPosts&page=${page}&limit=${postsPerPage}`
    )
      .then((response) => response.json())
      .then((data) => {
        setPosts(data.posts);
        setTotalPages(data.pagination.total_pages);
        setCurrentPage(data.pagination.current_page);
      })
      .catch((error) => console.error("Error fetching posts:", error));
  };

  useEffect(() => {
    fetchPosts(currentPage);
  }, [currentPage]);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
    if (postsSectionRef.current) {
      postsSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="home-container">
      {/* Helmet for Meta Tags */}
      <Helmet>
        <title>Issani - Home</title>
        <meta name="description" content="
ಇಸ್ಸನಿ ಎಂದರೆ ಜೋರು ಮಳೆಗಾಲದಲ್ಲಿ ಬೆಚ್ಚಗಿನ ಬಟ್ಟೆ ತೊಟ್ಟು, ಕಿಟಕಿಯಿಂದ ಇಣುಕಿ ನೋಡುವಾಗ, ಪುಳಕ್ಕನೆ ಮುಖಕ್ಕೆ ಬಂದು ಎರಚುವ ಸಣ್ಣ ಹನಿಗಳ ಪುಂಜ....ಆಹಾ ಇದೆಂತ ಮಧುರ ಅನುಭವ ಕೊಡುತ್ತದೆ ಅಲ್ವಾ ..ಅಂತಹದೇ ಅನುಭವ ಕೊಡುವ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬರೆದು ಪ್ರಕಟಿಸುವ ಉದ್ದೇಶ ನಮ್ಮದು..  

ಹೊಸ ಕನ್ನಡ ಬರಹಗಾರರಿಗೆ ವೇದಿಕೆಯಾಗಿ, ಕನ್ನಡದ ಹೊಸ ತಲೆಮಾರಿಗೆ ಕನ್ನಡದಲ್ಲಿ ಭಾವನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಹುಚ್ಚು ಹಿಡಿಸುವ ಸಲುವಾಗಿ,  ಓದುಗರಿಗೆ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬುತ್ತಿಯಾಗಿ ಕಟ್ಟಿ ಕೊಡುವ ಸಲುವಾಗಿ ಈ ಜಾಲತಾಣ ಸೃಷ್ಟಿಸಿದ್ದೇವೆ ...ನಿಮ್ಮ ಪ್ರೀತಿ, ಓದು, ಸಲಹೆ ಎಲ್ಲವೂ ಜೊತೆಗಿರಲಿ..." />
        <meta property="og:title" content="Issani - Home" />
        <meta property="og:description" content="
ಇಸ್ಸನಿ ಎಂದರೆ ಜೋರು ಮಳೆಗಾಲದಲ್ಲಿ ಬೆಚ್ಚಗಿನ ಬಟ್ಟೆ ತೊಟ್ಟು, ಕಿಟಕಿಯಿಂದ ಇಣುಕಿ ನೋಡುವಾಗ, ಪುಳಕ್ಕನೆ ಮುಖಕ್ಕೆ ಬಂದು ಎರಚುವ ಸಣ್ಣ ಹನಿಗಳ ಪುಂಜ....ಆಹಾ ಇದೆಂತ ಮಧುರ ಅನುಭವ ಕೊಡುತ್ತದೆ ಅಲ್ವಾ ..ಅಂತಹದೇ ಅನುಭವ ಕೊಡುವ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬರೆದು ಪ್ರಕಟಿಸುವ ಉದ್ದೇಶ ನಮ್ಮದು..  

ಹೊಸ ಕನ್ನಡ ಬರಹಗಾರರಿಗೆ ವೇದಿಕೆಯಾಗಿ, ಕನ್ನಡದ ಹೊಸ ತಲೆಮಾರಿಗೆ ಕನ್ನಡದಲ್ಲಿ ಭಾವನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಹುಚ್ಚು ಹಿಡಿಸುವ ಸಲುವಾಗಿ,  ಓದುಗರಿಗೆ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬುತ್ತಿಯಾಗಿ ಕಟ್ಟಿ ಕೊಡುವ ಸಲುವಾಗಿ ಈ ಜಾಲತಾಣ ಸೃಷ್ಟಿಸಿದ್ದೇವೆ ...ನಿಮ್ಮ ಪ್ರೀತಿ, ಓದು, ಸಲಹೆ ಎಲ್ಲವೂ ಜೊತೆಗಿರಲಿ..." />
        <meta property="og:type" content="website" />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:title" content="Issani - Home" />
        <meta property="twitter:description" content="
ಇಸ್ಸನಿ ಎಂದರೆ ಜೋರು ಮಳೆಗಾಲದಲ್ಲಿ ಬೆಚ್ಚಗಿನ ಬಟ್ಟೆ ತೊಟ್ಟು, ಕಿಟಕಿಯಿಂದ ಇಣುಕಿ ನೋಡುವಾಗ, ಪುಳಕ್ಕನೆ ಮುಖಕ್ಕೆ ಬಂದು ಎರಚುವ ಸಣ್ಣ ಹನಿಗಳ ಪುಂಜ....ಆಹಾ ಇದೆಂತ ಮಧುರ ಅನುಭವ ಕೊಡುತ್ತದೆ ಅಲ್ವಾ ..ಅಂತಹದೇ ಅನುಭವ ಕೊಡುವ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬರೆದು ಪ್ರಕಟಿಸುವ ಉದ್ದೇಶ ನಮ್ಮದು..  

ಹೊಸ ಕನ್ನಡ ಬರಹಗಾರರಿಗೆ ವೇದಿಕೆಯಾಗಿ, ಕನ್ನಡದ ಹೊಸ ತಲೆಮಾರಿಗೆ ಕನ್ನಡದಲ್ಲಿ ಭಾವನೆಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳುವ ಹುಚ್ಚು ಹಿಡಿಸುವ ಸಲುವಾಗಿ,  ಓದುಗರಿಗೆ ಕನ್ನಡ ಬರಹಗಳನ್ನು ಬುತ್ತಿಯಾಗಿ ಕಟ್ಟಿ ಕೊಡುವ ಸಲುವಾಗಿ ಈ ಜಾಲತಾಣ ಸೃಷ್ಟಿಸಿದ್ದೇವೆ ...ನಿಮ್ಮ ಪ್ರೀತಿ, ಓದು, ಸಲಹೆ ಎಲ್ಲವೂ ಜೊತೆಗಿರಲಿ..." />
        <meta property="twitter:image" content="https://issani.co.in/assets/logo.png" />
      </Helmet>

      {/* Ankana Horizontal Scroll */}
      <div className="ankana-carousel-section">
        <AnkanaHorizontalScroll />
      </div>

      {/* Posts Section */}
      <div className="posts-section" ref={postsSectionRef}>
        <h2 className="section-title">ಇತ್ತೀಚೆಗಿನ ಬರಹಗಳು</h2>
        <div className="posts">
          {posts.map((post) => (
            <div key={post.bid} className="post-card">
              {/* Post Image */}
              {post.img ? (
                <img
                  src={`https://issani.co.in/${post.img}`} // Adjust path for local images
                  alt={post.title}
                  className="post-card-image"
                />
              ) : (
                <div className="no-image-placeholder">No Image Available</div>
              )}

              {/* Post Title */}
              <h2>
                <a href={`/post/${post.bid}`}>{post.title}</a>
              </h2>

              {/* Post Summary */}
              <p>{post.body.substring(0, 100)}...</p>

              {/* Author Name */}
              {post.author_name && (
                <p className="post-author">
                  <a href={`/author/${post.author}`}>ಲೇಖಕರು- {post.author_name}</a>
                </p>
              )}
            </div>
          ))}
        </div>

        {/* Pagination */}
        <div className="pagination">
          <button
            onClick={() => handlePagination(currentPage - 1)}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() => handlePagination(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
