import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AnkanaList.css";

const AnkanaList = () => {
  const [ankanas, setAnkanas] = useState([]);

  useEffect(() => {
    const fetchAnkanas = async () => {
      try {
        const response = await fetch(
          "https://issani.co.in/api/api.php?api=getAnkanas"
        );
        const data = await response.json();
        setAnkanas(data);
      } catch (error) {
        console.error("Error fetching ankanas:", error);
      }
    };

    fetchAnkanas();
  }, []);

  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768, // Adjust for smaller screens
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="ankana-carousel-container">
      <Slider {...carouselSettings}>
        {ankanas.map((ankana) => (
          <div key={ankana.Ank_id} className="ankana-slide">
            <Link to={`/ankana/${ankana.Ank_id}`}>
              <img
                src={`/assets/${ankana.imgPath}`}
                alt="Ankana Thumbnail"
                className="ankana-image"
              />
            </Link>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default AnkanaList;
