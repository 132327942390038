import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import PostDetails from "./pages/PostDetails";
import AuthorDetails from "./pages/AuthorDetails";
import Authors from "./pages/Authors";
import AnkanaList from "./pages/AnkanaList";
import AnkanaDetails from "./pages/AnkanaDetails";
import Layout from "./components/Layout";
import ListAllAnkanas from "./pages/ListAllAnkanas";
import ContactUs from "./pages/ContactUs";
import AboutUs from "./pages/AboutUs";

const App = () => {
  return (
    <>
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/post/:postId" element={<PostDetails />} />
            <Route path="/author/:authorId" element={<AuthorDetails />} />
            <Route path="/list-authors" element={<Authors />} />
            <Route path="/ankanas" element={<AnkanaList />} />
            <Route path="/ankana/:ankaId" element={<AnkanaDetails />} />
            <Route path="/all-ankanas" element={<ListAllAnkanas />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/about" element={<AboutUs />} />
          </Routes>
        </Layout>
      </Router>
    </>
  );
};

export default App;
