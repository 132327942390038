import React, { useEffect, useState } from "react";
import { auth, googleProvider } from "../firebase/firebase";
import { signInWithPopup, onAuthStateChanged, signOut } from "firebase/auth";
import "./Login.css";

const Login = ({ onLoginSuccess }) => {
  const [user, setUser] = useState(null);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        onLoginSuccess(currentUser);
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, [onLoginSuccess]);

  // Login with Google
  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const loggedInUser = result.user;
      setUser(loggedInUser);
      onLoginSuccess(loggedInUser);
    } catch (error) {
      console.error("Google Login Error:", error);
    }
  };

  // Logout current user
  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  if (user) {
    return (
      <div className="logged-in-container">
        <div className="user-info">
          <img src={user.photoURL} alt={user.displayName} className="user-avatar" />
          <p>Welcome, {user.displayName}!</p>
        </div>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>`+`
      </div>
    );
  }

  return (
    <div className="login-container">
      <button className="google-login-button" onClick={handleGoogleLogin}>
        <img
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
          alt="Google Logo"
        />
        Sign in with Google
      </button>
      <p className="disclaimer">
        By signing in, you agree to share your name, email address, and display
        picture for display purposes in the comment section. Read our{" "}
        <a href="/privacy-policy" target="_blank">
          Privacy Policy
        </a>.
      </p>
    </div>
  );
  
};

export default Login;
