import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./AuthorDetails.css";

const AuthorDetails = () => {
  const { authorId } = useParams();
  const [author, setAuthor] = useState(null);
  const [posts, setPosts] = useState([]);
  const [ankana, setAnkana] = useState(null); // State for Ankana details
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const postsPerPage = 5; // Number of posts per page

  const postsSectionRef = useRef(null); // Ref for the posts section

  // Fetch author details, including Ankana if available
  useEffect(() => {
    const fetchAuthorDetails = async () => {
      try {
        const authorResponse = await fetch(
          `https://issani.co.in/api/api.php?api=getAuthorById&author_id=${authorId}`
        );
        const authorData = await authorResponse.json();

        if (authorData.error) {
          throw new Error(authorData.error);
        }

        setAuthor(authorData.author);

        // Check if the author has an Ankana
        if (authorData.author.ankana_id) {
          setAnkana({
            id: authorData.author.ankana_id,
            imgPath: authorData.author.ankana_imgPath,
          });
        }

        setLoading(false);
      } catch (error) {
        console.error("Error fetching author details:", error);
        setAuthor(null);
        setLoading(false);
      }
    };

    if (authorId) {
      fetchAuthorDetails();
    }
  }, [authorId]);

  // Fetch posts written by the author
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await fetch(
          `https://issani.co.in/api/api.php?api=getAuthorPosts&author_id=${authorId}&page=${currentPage}&limit=${postsPerPage}`
        );
        const data = await response.json();

        setPosts(data.posts || []);
        setTotalPages(data.pagination?.total_pages || 1);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, [authorId, currentPage]);

  const handlePagination = (newPage) => {
    setCurrentPage(newPage);
    if (postsSectionRef.current) {
      postsSectionRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  if (loading) {
    return <div>Loading author details...</div>;
  }

  if (!author) {
    return <div>No author details found.</div>;
  }

  // JSON-LD Structured Data for Author
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    name: author.name,
    description: author.about || "No description available.",
    image: author.pic
      ? `https://issani.co.in/assets/${author.pic}`
      : "https://issani.co.in/assets/default-author.png",
    url: `https://issani.co.in/author/${authorId}`,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `https://issani.co.in/author/${authorId}`,
    },
    worksFor: {
      "@type": "Organization",
      name: "Issani",
      url: "https://issani.co.in",
    }
  };

  return (
    <div className="authors-container">
      <Helmet>
        <title>{author.name} - Issani</title>
        <meta
          name="description"
          content={`Learn more about ${author.name} and their works.`}
        />
        <meta property="og:title" content={`${author.name} - Issani`} />
        <meta
          property="og:description"
          content={`Learn more about ${author.name} and their works.`}
        />
        <meta
          property="og:image"
          content={
            author.pic
              ? `https://issani.co.in/assets/${author.pic}`
              : "https://issani.co.in/assets/default-author.png"
          }
        />
        <meta
          property="og:url"
          content={`https://issani.co.in/author/${authorId}`}
        />
        <meta property="og:type" content="profile" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${author.name} - Issani`} />
        <meta
          name="twitter:description"
          content={`Learn more about ${author.name} and their works.`}
        />
        <meta
          name="twitter:image"
          content={
            author.pic
              ? `https://issani.co.in/assets/${author.pic}`
              : "https://issani.co.in/assets/default-author.png"
          }
        />
        <script type="application/ld+json">{JSON.stringify(structuredData)}</script>
      </Helmet>
      <div className="author-header">
        <img
          src={
            author.pic
              ? `/assets/${author.pic}`
              : "/assets/default-author.png"
          }
          alt={author.name}
          className="author-image"
        />
        <h1>{author.name || "Unknown Author"}</h1>
        <p>{author.about || "No description available."}</p>
        {ankana &&  <h2>ಇವರ ಅಂಕಣ ಬರಹಗಳು</h2>}
        {/* Display Ankana Thumbnail if available */}
        {ankana && (
          <a href={`/ankana/${ankana.id}`} className="ankana-link">
            <img
              src={`/assets/${ankana.imgPath}`}
              alt="Ankana Thumbnail"
              className="ankana-thumbnailauthor"
            />
          </a>
        )}
      </div>

      <div className="author-posts" ref={postsSectionRef}>
        <h2>ಇಸ್ಸನಿಯಲ್ಲಿರುವ {author.name} ಅವರ ಬರಹಗಳು</h2>
        <div className="posts-grid">
          {posts.map((post) => (
            <div key={post.bid} className="post-card-author">
              <h3>
                <a href={`/post/${post.bid}`}>{post.title}</a>
              </h3>
              <p>{post.body?.substring(0, 100)}...</p>
            </div>
          ))}
        </div>
        {/* Pagination Section */}
        {totalPages > 1 && (
          <div className="pagination">
            <button
              onClick={() => handlePagination(Math.max(currentPage - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => handlePagination(Math.min(currentPage + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthorDetails;